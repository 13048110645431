@import 'variables';
@import 'typography';
.splash-container {
    height: 100vh;
}

.checkboxes {
    margin: 0 -$gutter;
    label {
        flex: 0 0 175px;
    }
}

.form-flex-fixed {
    flex: 0 0 135px;
}

.input-group.row {
    .row {
        input {
            // width: 100px;
            flex: 0 0 100px;
            margin: 7.5px 0 !important;
            padding: 5px;
            margin: -5px;
        }
        label {
            flex: 0 0 70px;
            max-width: 70px;
        }
    }
}

.form-block-container {
    @media(min-width: $tl) {
        display: flex;
        flex-direction: row;
        .form-block {
            flex: 1 0 40%;
        }
    }
}

.not-an-input {
    background: none;
    border: none;
    font-size: 1em;
    line-height: $base;
    text-decoration: underline;
}

.alert-danger {
    color: $danger;
    font-size: 1em;
    font-weight: bold;
}

.video-info{
    @media(min-width:620px){
        margin-top:$gutter * 2.5;
    }
}
.Persisting{
    .form-group{
        background:#ccc;
        margin:$gutter;
    }
}
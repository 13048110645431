@import "fonts";

$base: 1em;
$baseFont: $montserrat;

* {
  font-family: $baseFont;
  color: $gray-dull;
  box-sizing: border-box;
  font-size: $base;
  line-height: $base * .2;
}

h5 {
  font-size: $base * 1.25;
  line-height: $base * 1.25;
  font-weight: 600;
}

h4 {
  font-size: $base * 1.3;
  line-height: $base * 1.3;
  font-weight: 600;
}

h3 {
  font-size: $base * 1.5;
  line-height: $base * 1.5;
  font-weight: 600;
}

h2 {
  font-size: $base * 1.75;
  line-height: $base * 1.75;
  font-weight: 700;
}

h1 {
  font-size: $base * 2;
  line-height: $base * 2;
  font-weight: 700;
}

li,
p {
  font-size: $base;
  font-weight: 400;
  letter-spacing: 1.2px;
  line-height: $base + .5;
}

aside {
  line-height: $base + .5;
  letter-spacing: 1.2px;
}

.extra-light {
  font-weight: 100;
}

.light {
  font-weight: 200;
}

.thin {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold,
strong {
  font-weight: 700;
}

.black {
  font-weight: 900;
}
/* spacing and breakpoints */

$gutter: 15px;
$sm: 375px; //small mobile
$lm: 525px; //large mobile
$ts: 575px; //tablet small
$tl: 750px; //tablet large
$ds: 975px; //desktop small
$dm: 1275px; // desktop medium
$dl: 1900px; //desktop large

/* Branding */

$brand-primary: #00B3A5;
$brand-alt:#336666;
$brand-secondary: #14314b;

/* Standards */

$white: #fff;
$black: #060606;
$gray-dull: #3f3f3f;
$gray-light: lighten($gray-dull, 20%);
$danger:#e33f5f;

/* Social */

$brand-facebook:#768BB7;
$brand-twitter:#88C5F3;
$brand-instagram : // Made possible by Katy DeCorah. https://codepen.io/katydecorah/#
radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
@import 'variables';
@import 'typography';

body.Login {
  // height: 100vh;
  background-image: url('../images/background-large.jpg');
  background-position: 50% 50%;
  background-size:cover;
 
  img{
      max-width: 100%;
      margin:0;
      padding:$gutter;
  }

  .grid {
    height:100vh;
    display: grid;
    grid-auto-columns: 1fr 2fr 1fr;
    grid-auto-rows: 1fr 2fr 1fr;
    grid-template-areas: 
    "header header header"
    "header content header"
    "header header header"
    ;
    .wrapper{
        grid-area: content;
    }
    //
    h2,
    label {
      color: white;
      padding-bottom: $gutter;
    }
    h2{
        margin:($gutter * 2) $gutter;;
        padding:0;
        // text-align: center;

    }
    .form-horizontal{
        justify-self: end;
        margin-top:$gutter;

    }
    input[type=password], input[type=email]{
        margin:$gutter;
        padding:5px;
    }
    button.btn{
        padding:($gutter / 1.25) $gutter;
        background-color:$white;
        border-width: 0;
        
    }
    a.btn-link{
        color:$white;
    }
  }
}

.checkboxes {
  margin: 0 -$gutter;

  label {
    flex: 0 0 175px;
  }
}

.form-flex-fixed {
  flex: 0 0 135px;
}

.input-group.row {
  .row {
    input {
      // width: 100px;
      flex: 0 0 100px;
      margin: 7.5px 0 !important;
      padding: 5px;
      margin: -5px;
    }

    label {
      flex: 0 0 70px;
      max-width: 70px;
    }
  }
}
.pages-container{
  h3, a, input{
    line-height: 1.5em;
  }
}
.form-block-container {
  @media(min-width: $tl) {
    display: flex;
    flex-direction: row;

    .form-block {
      flex: 1 0 40%;
    }
  }
}

.not-an-input {
  background: none;
  border: none;
  font-size: 1em;
  line-height: $base;
  text-decoration: underline;
  margin-bottom:0;
}

.alert-danger {
  color: $danger;
  font-size: 1em;
  font-weight: bold;
}

.video-info {
  @media(min-width:620px) {
    margin-top: $gutter * 2.5;
  }
}

.Persisting {
  .form-group {
    background: #ccc;
    margin: $gutter;
  }
}

.mce-tinymce.mce-container{
  margin:$gutter * 2;
}

.editable-content{
  width:100%;
  min-height:250px;
}

.mce_tinymce-inline{
  top:0 !important;
}
.mce-tinymce.mce-container{
  margin:0;
}